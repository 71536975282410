import { render, staticRenderFns } from "./SolicitudAdopcion.vue?vue&type=template&id=12fba992&scoped=true&"
import script from "./SolicitudAdopcion.vue?vue&type=script&lang=js&"
export * from "./SolicitudAdopcion.vue?vue&type=script&lang=js&"
import style0 from "./SolicitudAdopcion.vue?vue&type=style&index=0&id=12fba992&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fba992",
  null
  
)

export default component.exports