<template>
  <div>
    <b-card
      bg-variant="light"
      header="Solicitud Adopción"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-form
        @submit="modalEnviar"
        @reset="cancelarForm"
        align="left"
        id="formularioResiduos"
      >
        <b-form-row>
          <b-col cols="12" v-if="!editar">
            <b-button 
              type="button" 
              @click="activarModalBuscar"
              block
            >
              Seleccionar Mascota para adoptar
            </b-button>
          </b-col>

          <b-col cols="12" md="4">
            <div class="fotoMascota">
              <img :src="verImagenMascota" alt="foto mascota" />
            </div>
          </b-col>

          <b-col cols="12" md="8">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  id="group-nombre_animal"
                  label="Nombre mascota"
                  label-for="nombre_animal"
                >
                  <b-form-input
                    id="nombre_animal"
                    v-model="form.nombre_animal"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  id="group-iua"
                  label="Mascota solicitada IUA"
                  label-for="iua"
                >
                  <b-form-input
                    id="iua"
                    v-model="form.iua"
                    required
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>


          <b-col cols="12" md="4">
            <b-form-group
              id="group-nombre_persona_solicita"
              label="Nombre y apellidos del adoptante"
              label-for="nombre_persona_solicita"
            >
              <b-form-input
                id="nombre_persona_solicita"
                v-model="form.nombre_persona_solicita"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-iden_persona_solicita"
              label="Identificación"
              label-for="iden_persona_solicita"
            >
              <b-form-input
                id="iden_persona_solicita"
                v-model="form.iden_persona_solicita"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          

          <b-col cols="12" md="4">
            <b-form-group
              id="group-personas_familia"
              label="Personas en la familia"
              label-for="personas_familia"
            >
              <b-form-input
                id="personas_familia"
                v-model="form.personas_familia"
                type="number"
                min="1"
                max="10"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="group-composicion_hogar"
              label="Personas en la familia"
              label-for="composicion_hogar"
            >
              <b-form-input
                id="composicion_hogar"
                v-model="form.composicion_hogar"
                placeholder="Padre, Madre y 2 hermanos mayores"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="group-fot_ident"
              label="Imágen cédula de ciudadanía"
              label-for="fot_ident"
            >
              <b-form-file
                id="fot_ident"
                :state="Boolean(form.fot_ident)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept=".jpg, .JPG, .JPEG"
                @change="imagenCedula"
                v-if="!cargadoCedula"
                :disabled="verDetalles ? true : false"
              ></b-form-file>

              <b-button
                block
                variant="primary"
                class="mr-2"
                v-if="cargadoCedula"
                @click="descargarPDF(form.fot_ident, 'Cedula')"
              >
                Descargar
              </b-button>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="group-foto_servicios"
              label="Imagen recibo servicios públicos"
              label-for="foto_servicios"
            >
              <b-form-file
                id="foto_servicios"
                :state="Boolean(form.foto_servicios)"
                placeholder="Elige un archivo"
                browseText="Buscar"
                accept=".jpg, .JPG, .JPEG"
                @change="imagenServicios"
                v-if="!cargadoServicios"
                :disabled="verDetalles ? true : false"
              ></b-form-file>

              <b-button
                block
                variant="primary"
                class="mr-2"
                v-if="cargadoServicios"
                @click="descargarPDF(form.foto_servicios, 'Servicios')"
              >
                Descargar
              </b-button>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Tiene conocimiento la familia del proceso de adopción?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.conoce_familia"
                name="conoce_familia"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="¿Ha tenido mascotas antes?" label-cols="8">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.macotas_antes"
                name="macotas_antes"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="¿Tiene mascotas actualmente?" label-cols="8">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.tiene_macotas"
                name="tiene_macotas"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="¿Vive en casa propia?" label-cols="8">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.casa_propia"
                name="casa_propia"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Tiene espacio suficiente para la mascota?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.tiene_espacio"
                name="tiene_espacio"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Tiene tiempo disponible para cuidarlo?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.tiene_tiempo"
                name="tiene_tiempo"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="¿Tiene alergias?" label-cols="8">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.alergias"
                name="alergias"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="¿Planea mudarse?" label-cols="8">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.mudarse"
                name="mudarse"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Tiene en cuenta que la nueva mascota implica gastos?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.gastos"
                name="gastos"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Acepta capacitación por parte de la secretaría?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.acepta_capacitacion"
                name="acepta_capacitacion"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Comprende que la mascota requiere un tiempo de adaptación?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.adaptacion"
                name="adaptacion"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete con el bienestar de la mascota?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.bienestar"
                name="bienestar"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Comprende que al pasear su mascota debe recoger sus excretas y disponerlas adecuadamente?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.pasear"
                name="pasear"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete a tener actualizado el esquema de vacunación de la mascota"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.vacunacion"
                name="vacunacion"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete a tener actualizado el esquema de desparasitación de la mascota"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.desparasitacion"
                name="desparasitacion"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Comprende que eventualmente a ley 1774 de 2016 lo puede penalizar legalmente en caso se presentarse maltrato animal?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.leypenal"
                name="leypenal"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Acepta la responsabilidad que implica tener la mascota por el resto de la vida de esta?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.aceptacion_resp"
                name="aceptacion_resp"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete a cuidar a la mascota en caso de enermedad y asumir los costos que esto implique?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.cuidar_enfermedad"
                name="cuidar_enfermedad"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete de mantener informada a la Secretaría en caso de alguna novedad?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.informar_secretaria"
                name="informar_secretaria"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="¿Se compromete a brindar amor y respeto a la mascota?"
              label-cols="8"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.brindar_amor"
                name="brindar_amor"
                :disabled="verDetalles ? true : false"
              >
                <b-form-radio value="1">Si</b-form-radio>
                <b-form-radio value="0">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="form.estado != 2 && form.observaciones">
            <b-form-group
              id="group-observaciones"
              label="Observaciones"
              label-for="observaciones"
              :disabled="verDetalles ? true : false"
            >
              <b-form-textarea
                id="observaciones"
                v-model="form.observaciones"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="form.estado == 0">
            <b-form-group
              id="group-causa_rechazo"
              label="Causa Rechazo"
              label-for="causa_rechazo"
              :disabled="verDetalles ? true : false"
            >
              <b-form-textarea
                id="causa_rechazo"
                v-model="form.causa_rechazo"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-right">
            <b-button
              v-if="form.estado == 2 && !vistaPersona"
              variant="primary"
              class="mr-2"
              @click="mostrarObservacionesYCausas(false)"
            >
              Rechazar
            </b-button>

            <b-button
              v-if="form.estado == 2 && !vistaPersona"
              variant="primary"
              class="mr-2"
              @click="mostrarObservacionesYCausas(true)"
            >
              Aprobar
            </b-button>

            <b-button
              v-if="editar ? false : true"
              type="submit"
              variant="primary"
              class="mr-2"
              :disabled="verDetalles ? true : false"
            >
              Guardar
            </b-button>

            <b-button type="reset">Cancelar</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>

    <b-modal
      id="modalRechazo"
      v-model="mostrarModalRechazo"
      hide-footer
      size="lg"
    >
      <template #modal-title>Detalles</template>
      <div class="d-block p-3">
        <b-row>
          <b-col cols="12">
            <b-form-group
              id="group-observaciones"
              label="Observaciones"
              label-for="observaciones"
            >
              <b-form-textarea
                id="observaciones"
                v-model="form.observaciones"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="!booleanAprobar">
            <b-form-group
              id="group-causa_rechazo"
              label="Describa la causa de rechazo"
              label-for="causa_rechazo"
            >
              <b-form-textarea
                id="causa_rechazo"
                v-model="form.causa_rechazo"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col class="text-right" v-if="!booleanAprobar">
            <b-button @click="rechazar" align-self="end"> Rechazar </b-button>
          </b-col>

          <b-col class="text-right" v-if="booleanAprobar">
            <b-button @click="aprobar" align-self="end"> Aprobar </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      id="modalBuscarMascota"
      v-model="mostrarModalBuscarBoolean"
      hide-footer
      size="lg"
      @hide="cerrarModalBuscar"
    >
      <template #modal-title> Buscar Mascotas </template>
      <div class="d-block text-center p-3">
        <FormularioBuscarMascotas @recibirMascota="cerrarModalBuscar"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../../contantesErrores";
import moment from "moment";
import FormularioBuscarMascotas from "../adopcion/buscarMascota/FormularioBuscarMascota.vue";
import ImagenMascota from "../../../assets/imgCabeceras/fotoAnimalesDefault.jpg";
export default {
  name: "SolicitudAdopcion",
  components: {
    FormularioBuscarMascotas,
  },
  data() {
    return {
      editar:false,
      vistaPersona: false,
      booleanAprobar: true,
      verDetalles: false,
      cargadoCedula: false,
      cargadoServicios: false,
      mostrarModalRechazo: false,
      mostrarModalBuscarBoolean: false,
      usuario: {},
      persona: {},
      fotoMascota: null,

      form: {
        id: null,
        id_animal: null,
        nombre_animal: null,
        iua: null,
        desc_tipo_animal: null,
        id_persona: null,
        nombre_persona_solicita: null,
        iden_persona_solicita: null,
        fot_ident: null,
        foto_servicios: null,
        fecha_solicitud: moment(),
        id_usuario: null,
        nombre_funcionario_aprueba: null,
        fecha_respuesta: null,
        observaciones: null,
        estado: 2,
        causa_rechazo: null,
        personas_familia: null,
        composicion_hogar: null,
        conoce_familia: null,
        macotas_antes: null,
        tiene_macotas: null,
        casa_propia: null,
        tiene_espacio: null,
        tiene_tiempo: null,
        alergias: null,
        mudarse: null,
        gastos: null,
        acepta_capacitacion: null,
        adaptacion: null,
        bienestar: null,
        pasear: null,
        vacunacion: null,
        desparasitacion: null,
        leypenal: null,
        aceptacion_resp: null,
        cuidar_enfermedad: null,
        informar_secretaria: null,
        brindar_amor: null,
      },
    };
  },
  async mounted() {
    // if (this.solicitudesAdopcionSeleccionada.activarSoloVista) {
    //   this.verDetalles = true;
    // }

    if (this.solicitudesAdopcionSeleccionada.id) {
      this.editar=true
      this.verDetalles=true;
      this.traerAnimalById(this.solicitudesAdopcionSeleccionada.id_animal)
      this.cargarSolicitud(this.solicitudesAdopcionSeleccionada.id);
    }

    if (localStorage.getItem("usuario")) {
      try {
        this.usuario = JSON.parse(localStorage.getItem("usuario"));
      } catch (e) {
        localStorage.removeItem("usuario");
      }
    }

    if (localStorage.getItem("persona")) {
      try {
        this.persona = JSON.parse(localStorage.getItem("persona"));
        if (this.persona.id_persona) {
          this.vistaPersona = true;
          if(!this.verDetalles){
            this.mapearPersona();
          }
          console.log("existe persona", this.persona);
        }
      } catch (e) {
        localStorage.removeItem("persona");
      }
    }

    
  },
  computed: {
    ...mapState("animales", [
      "solicitudesAdopcionSeleccionada",
      "solicitudAdopcionCargada",
      "animalSeleccionado",
      "resultCreateSolicitudAdopcion",
      "animalCargado"
    ]),
    ...mapState("animalesPersona", ["resultCreateAnimalPersona", "animalPersonaCargado"]),

    verImagenMascota() {
      let imagen = ImagenMascota;
      if (this.fotoMascota) {
        imagen = `data:image/jpg;base64,${this.fotoMascota}`;
      }
      return imagen;
    },
  },
  methods: {
    ...mapActions(["activarLoading"]),
    ...mapActions("animales", [
      "cargarSolicitudAdopcionById",
      "editarSolicitudAdopcion",
      "crearSolicitudAdopcion",
      "cargarAnimalById",
      "resetAnimalesServicio",
    ]),
    ...mapActions("animalesPersona", ["crearAnimalPersona", "getAnimalPersonaByIdAnimal"]),

    mapearPersona(){
      this.form.id_persona = this.persona.id_persona
      this.form.nombre_persona_solicita = this.persona.nombre_persona
      this.form.iden_persona_solicita = this.persona.login
    },

    async traerAnimalById(id) {
      try {
        await this.cargarAnimalById(id);
        console.log("esto es el animal cargado", this.animalCargado);
        if (this.animalCargado.length > 0) {
          this.fotoMascota = this.animalCargado[0].foto;
        }
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    async cargarSolicitud(id) {
      await this.cargarSolicitudAdopcionById(id);
      this.form = this.solicitudAdopcionCargada[0];
      this.form.id_persona = this.solicitudAdopcionCargada[0].id_persona_solicita;
      this.cargadoCedula = this.form.fot_ident?true:false
      this.cargadoServicios = this.form.foto_servicios?true:false
      console.log('form', this.form)
      console.log('saC', this.solicitudAdopcionCargada)
    },

    imagenCedula(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "cedula");
    },

    imagenServicios(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo, "servicios");
    },

    crearBase64(file, lugar) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let archivo = e.target.result;
          let base = archivo.split("base64,");
          let pdf = base[1];

          
          if (lugar == "cedula") {
            this.form.fot_ident = pdf;
          }
          if (lugar == "cedula") {
            this.form.fot_ident = pdf;
          }
          if (lugar == "servicios") {
            this.form.foto_servicios = pdf;
          }
        };
        reader.readAsDataURL(file);
      } else {
       
        if (lugar == "cedula") {
          this.form.fot_ident = null;
        }
        if (lugar == "servicios") {
          this.form.foto_servicios = null;
        }
      }
    },

    descargarPDF(pdf, nombre) {
      const linkSource = `data:image/jpg;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `imagen_${nombre}.jpg`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    modalEnviar(e) {
      e.preventDefault()
      this.$bvModal
        .msgBoxConfirm("Se enviará a aprobación la solicitud, no podrá realizar cambios adicionales ¿Desea continuar?.", {
          title: "Por favor confirme",
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Continuar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.enviarSolicitud();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async enviarSolicitud(){
      this.activarLoading(true)
      console.log(this.form);
      let datosAGuardar = {
        solicitudadopcion: this.form
      }

      console.log(datosAGuardar)

      try {
        await this.crearSolicitudAdopcion(datosAGuardar);
        if (this.resultCreateSolicitudAdopcion.data.status == 200) {
          this.activarLoading(false);
          this.modalOk();
        }
      } catch (e) {
        this.activarLoading(false);
        encontrarError(this, e.data);
      }
    },

    cancelarForm() {
      this.resetForm();
      this.$emit("cerrarModal");
    },

    aprobar() {
      if (this.form.observaciones && this.form.observaciones != "") {
        this.$bvModal
          .msgBoxConfirm("Se va a Aprobar la solicitud, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.aprobarSolicitud();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.modalMensaje("Por favor rellene el campo de Observaciones");
      }
    },

    async aprobarSolicitud() {
      this.form.id_usuario = this.usuario.id_usuario;

      try {
        await this.getAnimalPersonaByIdAnimal(this.form.id_animal)
        console.log('esto es el registro antes de aprobar', this.animalPersonaCargado)
        if(this.animalPersonaCargado.length==0){
          let animalPersona = {
            animalpersona: {
              id_animal: this.form.id_animal,
              id_persona: this.form.id_persona,
              fecha_registro: moment(),
              registrado_censo: 0,
              id_registro_adopcion: this.form.id,
              id_usuario: this.usuario.id_usuario,
              fecha_entrega_adop: moment(),
              estado_fisico_adop: null,
              observaciones_adop: this.form.observaciones,
              firma_persona: null,
            },
          };
          await this.crearAnimalPersona(animalPersona);
          if (this.resultCreateAnimalPersona.data.status == 200) {
            this.form.estado = 1;
            let solicitud = {
              solicitudadopcion: this.form,
            };
            await this.editarSolicitudAdopcion(solicitud);
            this.modalOk();
          }
        }else{
          this.rechazarAutomaticamente()
        }
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    mostrarObservacionesYCausas(action) {
      this.form.fecha_respuesta = moment();
      this.booleanAprobar = action;
      console.log("action");
      this.mostrarModalRechazo = true;
    },

    rechazarAutomaticamente(){
      this.form.causa_rechazo = 'Solicitud rechazada automáticamente, el animal ya no se encuentra disponible'
      this.form.observaciones = 'Solicitud rechazada automáticamente, el animal ya no se encuentra disponible'
      this.$bvModal
      .msgBoxOk("La solicitud será rechazada porque el animal no esta disponible.", {
        title: "Por favor confirme",
        size: "md",
        buttonSize: "sm",
        okVariant: "primary",
        okTitle: "Continuar",
        cancelTitle: "Cancelar",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(() => {
          this.rechazarSolicitud();
      })
      .catch((err) => {
        console.log(err);
      });
    },

    rechazar() {
      if (
        this.form.causa_rechazo &&
        this.form.causa_rechazo != "" &&
        this.form.observaciones &&
        this.form.observaciones != ""
      ) {
        this.$bvModal
          .msgBoxConfirm("Se va a Rechazar la solicitud, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.rechazarSolicitud();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.modalMensaje(
          "Por favor rellene los campos de Observación y Causa rechazo"
        );
      }
    },

    async rechazarSolicitud() {
      this.form.id_usuario = this.usuario.id_usuario;
      this.form.estado = 0;
      let solicitud = {
        solicitudadopcion: this.form,
      };
      try {
        await this.editarSolicitudAdopcion(solicitud);
        this.modalOk();
      } catch (e) {
        encontrarError(this, e.data);
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(async () => {
          this.cancelarForm();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    activarModalBuscar(){
      this.mostrarModalBuscarBoolean = true
    },

    cerrarModalBuscar(){
      this.mostrarModalBuscarBoolean = false
      console.log('animal seleccionado', this.animalSeleccionado)
      if(this.animalSeleccionado.id){
        this.mapearAnimalSeleccionado()
        this.traerAnimalById(this.animalSeleccionado.id)
      }
      this.resetAnimalesServicio();
    },

    mapearAnimalSeleccionado(){
      this.form.id_animal = this.animalSeleccionado.id
      this.form.nombre_animal = this.animalSeleccionado.nombre
      this.form.iua = this.animalSeleccionado.iua
      this.form.desc_tipo_animal = this.animalSeleccionado.descripcion_tipo_animal
    },

    resetForm(){
      this.fotoMascota = null
      this.form = {
        id: null,
        id_animal: null,
        nombre_animal: null,
        iua: null,
        desc_tipo_animal: null,
        id_persona: null,
        nombre_persona_solicita: null,
        iden_persona_solicita: null,
        fot_ident: null,
        foto_servicios: null,
        fecha_solicitud: null,
        id_usuario: null,
        nombre_funcionario_aprueba: null,
        fecha_respuesta: null,
        observaciones: null,
        estado: null,
        causa_rechazo: null,
        personas_familia: null,
        composicion_hogar: null,
        conoce_familia: null,
        macotas_antes: null,
        tiene_macotas: null,
        casa_propia: null,
        tiene_espacio: null,
        tiene_tiempo: null,
        alergias: null,
        mudarse: null,
        gastos: null,
        acepta_capacitacion: null,
        adaptacion: null,
        bienestar: null,
        pasear: null,
        vacunacion: null,
        desparasitacion: null,
        leypenal: null,
        aceptacion_resp: null,
        cuidar_enfermedad: null,
        informar_secretaria: null,
        brindar_amor: null,
      }
    }
  },
};
</script>

<style scoped>
  .fotoMascota {
    overflow: hidden;
    height: 210px;
    position: relative;
  }

  .fotoMascota img {
    position: relative;
    margin-top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
  }
</style>