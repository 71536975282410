<template>
  <div>
    <b-form @submit.prevent="enviarForm">
      <b-form-row>
        <b-col cols="12" md="3" order="1">
          <label for="iua">IUA: </label>
          <b-form-input
            id="iua"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="IUA"
            v-model="form.iua"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3" order="2">
          <label for="id_tipo_animal">Tipo animal</label>
          <b-form-select
            id="id_tipo_animal"
            v-model="form.id_tipo_animal"
            :options="mapearTipoAnimal"
            class="mb-3"
            @change="cargarRazas"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" order="3">
          <label for="id_raza">Tipo raza</label>
          <b-form-select
            id="id_raza"
            v-model="form.id_raza"
            :options="mapearTipoRaza"
            class="mb-3"
            :disabled="tiposRazas.length == 0"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" align-self="end" order-md="4" order="6">
          <b-button
            class="vertical-align mb-3"
            variant="secondary"
            type="submit"
            block
          >
            <b-icon icon="search"></b-icon>
            Consultar
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>

    <br />
    <br />

    <b-row v-if="animalesFiltrados.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="animalesFiltrados"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="animalesFiltrados.length > 0">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaLicencias"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { encontrarError } from "../../../../contantesErrores";
  import moment from 'moment'
  export default {
    name: "FormularioConsultaAnimales",
    data() {
      return {
        animalSelec: {},
        animalesFiltrados:[],
        form: {
          iua: null,
          id_tipo_animal: null,
          id_raza: null,
          sin_responsable: 1,
        },

        camposTabla: [
          {
            key: "nombre",
            label: "Nombre Mascota",
          },
          {
            key: "descripcion_tipo_animal",
            label: "Tipo Animal",
          },
          {
            key: "nombre_raza",
            label: "Raza",
          },
          "color",
          {
            key: "fecha_nacimiento",
            label: "Edad",
            formatter: "calcularEdad",
          },
          {
            key: "sexo",
            formatter: "mostrarSexo",
          },
        ],
        perPage: 10,
        currentPage: 1,
      };
    },
    async mounted() {
      try {
        await this.cargarTiposAnimales();
      } catch (e) {
        encontrarError(this, e.data);
      }
    },
    computed: {
      ...mapState("animales", [
        "tiposAnimales",
        "tiposRazas",
        "animalSeleccionado",
        "animalesServicio",
        "solicitudesAdopcionServicio",
      ]),

      rows() {
        return this.animalesFiltrados.length;
      },

      mapearTipoAnimal() {
        let mapeadas = [];
        this.tiposAnimales.filter((item) => {
          if (item.descripcion != "Otro") {
            let animal = {
              value: item.id,
              text: item.descripcion,
            };
            mapeadas.push(animal);
          }
        });

        let first = {
          value: null,
          text: "Seleccione una opción",
        };
        mapeadas.unshift(first);
        return mapeadas;
      },

      mapearTipoRaza() {
        let mapeadas = this.tiposRazas.map((item) => {
          let raza = {
            value: item.id,
            text: item.nombre,
          };
          return raza;
        });
        let first = {
          value: null,
          text: "Seleccione una opción",
        };
        mapeadas.unshift(first);
        return mapeadas;
      },
    },
    methods: {
      ...mapActions("animales", [
        "buscarAnimales",
        "cargarTiposRazasByTipoAnimal",
        "resetTipoRaza",
        "cargarTiposAnimales",
        "seleccionarAnimal",
        "resetAnimalesServicio"
      ]),

      filtrarAnimales() {
        let animalesFiltrados = [...this.animalesServicio]

        this.solicitudesAdopcionServicio.forEach(solicitud=>{
          let indexAnimalExistente = animalesFiltrados.findIndex((anmim)=>{
            return anmim.id == solicitud.id_animal
          })

          if(indexAnimalExistente != -1){
            console.log('aqui', solicitud)
            animalesFiltrados.splice(indexAnimalExistente,1)
          }
        })

        this.animalesFiltrados = animalesFiltrados
      },

      async cargarRazas(id) {
        if (id) {
          try {
            await this.cargarTiposRazasByTipoAnimal(id);
          } catch (e) {
            encontrarError(this, e.data);
          }
        } else {
          await this.resetTipoRaza();
        }
      },

      async enviarForm() {
        let filtros = {
          animalparams: this.form,
        };
        try {
          await this.buscarAnimales(filtros);
          this.filtrarAnimales()
        } catch (e) {
          encontrarError(this, e.data);
        }
      },

      mostrarSexo(value) {
        if (value == 2) {
          return "Hembra";
        } else {
          return "Macho";
        }
      },

      calcularEdad(value) {
        let edad = null;
        if (value != null) {
          let fechaNacimiento = moment(value, "YYYY-MM-DD");
          let fechaActual = moment();
          edad = fechaActual.diff(fechaNacimiento, "years");
        }
        return edad;
      },

      rowClass(item, type) {
        if (!item || type !== "row") return;
        return "rowspace";
      },

      onRowSelected(items) {
        if (items.length > 0) {
          this.seleccionarAnimal(items[0]);
        } else {
          this.seleccionarAnimal({});
        }

        this.form= {
          iua: null,
          id_tipo_animal: null,
          id_raza: null,
          sin_responsable: 1,
        }
        this.$emit('recibirMascota')
      },
    },
  };
</script>